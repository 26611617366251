const requiredString = (value) => {
  return !!value && typeof value === "string" && value.trim().length > 0;
};

const requiredPhone = (value) => {
  return (
    !!value &&
    typeof value === "string" &&
    value.trim().length === 10 &&
    !isNaN(value)
  );
};

const requiredZipcode = (value) => {
  return (
    !!value &&
    typeof value === "string" &&
    value.trim().length === 5 &&
    !isNaN(value)
  );
};

const requiredEmail = (value) => {
  return (
    !!value &&
    typeof value === "string" &&
    value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) != null
  );
};

const requiredDate = (value) => {
  return !!value && typeof value === "string" && value.trim().length === 10;
};

const isValidForm = (formErrors) => {
  return !Object.keys(formErrors).some((key) => formErrors[key] === false);

  //return !Object.values(formErrors).includes(false);
};

const addLabelErrorClass = (formErrors, fieldId) => {
  return formErrors[fieldId] === false ? "text-danger" : "";
};

const addInputErrorClass = (formErrors, fieldId) => {
  return formErrors[fieldId] === false ? "is-invalid" : "";
};

export {
  requiredString,
  requiredPhone,
  requiredEmail,
  requiredDate,
  requiredZipcode,
  isValidForm,
  addLabelErrorClass,
  addInputErrorClass,
};
