import { Carousel } from "react-responsive-carousel";
import React from "react";

class Advertisement extends React.Component {
  onClickItem = (item) => {
    switch (item) {
      case 0:
        window.location = "/#/application?campName=DS";
        break;
      case 1:
        window.location = "/#/application?campName=SDET";
        break;
      case 2:
        window.location = "/#/application?campName=WEB";
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <section className="section bg-gray">
        <div className="container">
          <div className="row">
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              onClickItem={this.onClickItem}
            >
              <div>
                <img
                  alt="DS Boot Camp"
                  src="images/advertisement/adv.ds.jpg"
                />
                <p className="legend d-none d-sm-block">
                  Data Science Boot Camp Tech Stack
                </p>
              </div>
              <div>
                <img
                  alt="SDET Boot Camp"
                  src="images/advertisement/adv.sdet.jpg"
                />
                <p className="legend d-none d-sm-block">
                  SDET Boot Camp Tech Stack
                </p>
              </div>
              <div>
                <img
                  alt="WEB Boot Camp"
                  src="images/advertisement/adv.web.jpg"
                />
                <p className="legend d-none d-sm-block">
                  Web Development Boot Camp Tech Stack
                </p>
              </div>
            </Carousel>
          </div>
        </div>
      </section>
    );
  }
}
export default Advertisement;
