import { Link } from "react-router-dom";
import React from "react";

class Process extends React.Component {
  render() {
    return (
      <section className="section bg-web-desc" id="process">
        <div className="bg-overlay" />
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-white text-center">
                Boot Camp Process
              </h1>
              <div className="section-title-border margin-t-20" />
              <p className="section-subtitle text-light text-center font-secondary padding-t-30">
                You are just a step away from enhancing your career...
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 text-white text-center process-left-icon-1">
              <i className="pe-7s-angle-right" />
            </div>
            <div className="col-lg-6 text-white text-center process-left-icon-2">
              <i className="pe-7s-angle-right" />
            </div>
          </div>
          <div className="row margin-t-50">
            <div className="col-lg-4 plan-line">
              <div className="text-white text-center process-box">
                <i className="pe-7s-note2" />
                <h4 className="padding-t-15">Enrollment</h4>
                <p className="text-light">
                  Select a boot camp you want to be specialized
                </p>
              </div>
            </div>
            <div className="col-lg-4 plan-line">
              <div className="text-white text-center process-box">
                <i className="pe-7s-study" />
                <h4 className="padding-t-15">Training</h4>
                <p className="text-light">
                  Learn and develop professional skills
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text-white text-center process-box">
                <i className="pe-7s-target" />
                <h4 className="padding-t-15">Job Placement</h4>
                <p className="text-light">We will assist on career guidance</p>
              </div>
            </div>
            <div className="text-white text-center mx-auto">
              <Link
                to="application"
                className="btn btn-custom waves-light waves-effect margin-t-50"
              >
                Get Started <i className="mdi mdi-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Process;
