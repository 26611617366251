import * as helper from "../../helper";

import PropTypes from "prop-types";
import React from "react";

class Batch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: this.props.batchInfo, formErrors: {} };
  }

  handleUserInput = (event) => {
    const updatedFormData = { ...this.state.formData };
    updatedFormData[event.target.id] = event.target.value;
    this.setState({ formData: updatedFormData });
  };

  _onNext = (event) => {
    event.preventDefault();
    const formData = this.state.formData;
    const formErrors = {
      campName: helper.requiredString(formData.campName),
      batch: helper.requiredString(formData.batch),
    };
    if (helper.isValidForm(formErrors)) {
      this.props.handleFormSubmit(formData);
    } else {
      this.setState({ formErrors });
    }
  };

  render() {
    const { formData, formErrors } = this.state;
    return (
      <form onSubmit={this._onNext} id="batch">
        <div className="text-center mt-3">
          <h3 className="font-weight-bold">Boot Camp Selection</h3>
          <p className="text-muted">Enrollment Application: Step 1 of 6</p>
        </div>
        <div className="form-group">
          <label
            htmlFor="campName"
            className={helper.addLabelErrorClass(formErrors, "campName")}
          >
            Boot Camp*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(
              formErrors,
              "campName"
            )}`}
            id="campName"
            onChange={this.handleUserInput}
            defaultValue={formData.campName}
          >
            <option value="">-- Select --</option>
            <option value="DS">Data Science</option>
            <option value="SDET">
              SDET (Software Development Engineer in Test)
            </option>
            <option value="WEB">Web Developer</option>
          </select>
        </div>
        <div className="form-group">
          <label
            htmlFor="batch"
            className={helper.addLabelErrorClass(formErrors, "batch")}
          >
            Batch*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(
              formErrors,
              "batch"
            )}`}
            id="batch"
            onChange={this.handleUserInput}
            defaultValue={formData.batch}
          >
            <option value="">-- Select --</option>
            <option value="202312">Start in December 2023</option>
            <option value="202406">Start in June 2024</option>
            <option value="202506">Start in June 2025</option>
          </select>
        </div>
        <div className="mt-3">
          <button type="submit" className="btn btn-custom btn-block">
            Next
          </button>
        </div>
      </form>
    );
  }
}

Batch.propTypes = {
  handleFormSubmit: PropTypes.func,
  batchInfo: PropTypes.object,
};

export default Batch;
