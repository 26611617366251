import PropTypes from 'prop-types';
import React from 'react';

import Error from './Error';
import Success from './Success';

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { message } = this.props || {};
    return (
      <div>
        {message && message.type === 'success' && <Success text={message.text} />}
        {message && message.type === 'error' && <Error text={message.text} />}
      </div>
    );
  }
}

Message.propTypes = {
  message: PropTypes.object
};

export default Message;
