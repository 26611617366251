import React from "react";

class Navbar extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
        <div className="container">
          <a className="navbar-brand logo text-uppercase" href="/">
            <img src="images/codingIsd-logo-sm.png" alt="CodingISD Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav navbar-center" id="mySidenav">
              <li className="nav-item active">
                <a
                  href="#home"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Home
                </a>
              </li>
              {/*<li className='nav-item'>
                <a href='#services' className='nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  Services
                </a>
							</li>*/}
              <li className="nav-item">
                <a
                  href="#bootCamps"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Boot Camps
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#process"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Get Started
                </a>
              </li>
              {/*<li className='nav-item'>
                <a href='#team' className='nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  Team
                </a>
						</li>*/}
              <li className="nav-item">
                <a
                  href="#contact"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
