import PropTypes from 'prop-types';
import React from 'react';

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='container pl-0 pr-0'>
        <div className='row'>
          <div className='col-lg-12 margin-t-30'>
            <div className='success-message d-table'>
              <div className='d-table-cell align-middle'>
                <i className='pe-7s-smile text-custom' />
              </div>
              <div className='d-table-cell align-middle'>
                <p className='text-muted mb-0'>
                  <b>Succeed:</b> {this.props.text}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  text: PropTypes.string
};

export default Success;
