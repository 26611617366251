import Advertisement from "../components/Advertisement";
//import Training from '../components/Training';
import Aux from "../hoc/Aux_";
import BootCamps from "../components/BootCamps";
import Contact from "../components/Contact";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Process from "../components/Process";
import React from "react";
import SocialMedia from "../components/SocialMedia";
//import Services from '../components/Services';
import Testimony from '../components/Testimony';

//import Team from '../components/Team';

//import { Link } from 'react-router-dom';
//import FooterLinks from './components/FooterLinks';

class Home extends React.Component {
  render() {
    const bkg1 = {
      backgroundImage: "url(images/bg-home.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center",
    };

    return (
      <Aux>
        {/* Navbar Component*/}
        <div>
          <Navbar />
        </div>
        <section
          className="section bg-home home-half"
          id="home"
          data-image-src="images/bg-home.jpg"
          style={bkg1}
        >
          <div className="bg-overlay" />
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  {/* <div className="col-lg-9 offset-lg-2 text-white text-center">
                    <h1 className="home-title">
                      Start your IT career at CodingISD
                    </h1>
                    <p className="padding-t-15 home-desc">
                      CodingISD offers wide range of software training and job
                      placement assistant service in the US. Long–term,
                      short–term, refresher or follow-up courses and programs
                      are being offered. We ascertain that the syllabus and
                      curriculum of each course is well mapped with the
                      requirements of the IT employers. The followings are some
                      the principal boot camps offered by us.
                    </p>
                    <p className="play-shadow margin-t-30 margin-l-r-auto">
                      {" "}
                    </p>
                  </div> */}
                  <div className="col-lg-9 offset-lg-2 text-white text-center">
                    <h1 className="home-title">
                    Data Science Boot Camp Orientation
                    </h1>
                    <div className="col-lg-8 offset-lg-2">
                      <div>When: Dec 09, 2023, 11:00 AM CST</div>
                      <div>Address: 2301 Ohio Dr, #137, Plano TX, 75093</div>
                      <div>Phone dial-in: 855-552-4463</div>
                      <div>Online meeting ID: codingisd-usa</div>
                      <div><a className="btn btn-bg-white margin-t-30 waves-effect waves-light mb-5" href="https://chime.aws/codingisd-usa">Join Virtual</a></div>
                    </div>
                    <p className="play-shadow margin-t-30 margin-l-r-auto">
                      {" "}
                      {/* <Link to='http://vimeo.com/99025203' className='play-btn video-play-icon'> 
                        <i className='mdi mdi-play text-center' />
                      </Link>*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Services Component
        <Services />*/}

        {/* BootCamps Component*/}
        <BootCamps />

        {/* Process Component*/}
        <Process />

        {/* Training Component
        <Training />*/}

        {/* Advertisement*/}
        <Advertisement />

        {/* Features Component*/}
        <Features />

        {/* Team Component
        <Team />*/}

        {/* Testimony Component*/}
        <Testimony />

        {/* Contact Component*/}
        <Contact />

        {/* SocialMedia Component*/}
        <SocialMedia />

        {/* Footer Component*/}
        <Footer />

        {/* FooterLinks Component
        <FooterLinks />*/}
      </Aux>
    );
  }
}

export default Home;
