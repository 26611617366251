import React from 'react';

class SocialMedia extends React.Component {
  render() {
    return (
      <section className='cta bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <ul className='list-inline social margin-t-20'>
                <li className='list-inline-item'>
                  {' '}
                  <a href='https://www.facebook.com/codingisd' className='social-icon'>
                    <i className='mdi mdi-facebook' />
                  </a>
                </li>
                <li className='list-inline-item'>
                  {' '}
                  <a href='https://twitter.com/codingisd' className='social-icon'>
                    <i className='mdi mdi-twitter' />
                  </a>
                </li>
                <li className='list-inline-item'>
                  {' '}
                  <a href='https://www.linkedin.com/company/codingisd' className='social-icon'>
                    <i className='mdi mdi-linkedin' />
                  </a>
                </li>
              </ul>
            </div>
            <div className='col-lg-4 margin-t-30 text-center'>
              <p className='margin-b-0 contact-title'>
                <i className='pe-7s-call' /> &nbsp;<a href='tel:1-214-799-0139'>214-799-0139</a>
              </p>
            </div>
            <div className='col-lg-4 margin-t-30 text-right'>
              <p className='contact-title'>
                <i className='pe-7s-mail-open' />
                &nbsp; <a href='mailto: info@codingisd.com'>info@codingisd.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default SocialMedia;
