import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";

import Application from "./pages/Application";
import Home from "./pages/Home";

//import Login from './Login';
//import PasswordForget from './PasswordForget';
//import SignUp from './SignUp';

class Root extends React.Component {
  render() {
    return (
      <HashRouter basename={"/"}>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route
            path={`${process.env.PUBLIC_URL}/application`}
            component={Application}
          />
          {/* <Route path={`${process.env.PUBLIC_URL}/password-forget`} component={PasswordForget} />
          <Route path={`${process.env.PUBLIC_URL}/sign-up`} component={SignUp} /> */}
        </Switch>
      </HashRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
