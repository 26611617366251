import PropTypes from 'prop-types';
import React from 'react';

import * as helper from '../../helper';

class Education extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: this.props.educationInfo, formErrors: {} };
  }

  handleUserInput = event => {
    const updatedFormData = { ...this.state.formData };
    updatedFormData[event.target.id] = event.target.value;
    this.setState({ formData: updatedFormData });
  };

  _onNext = event => {
    event.preventDefault();
    const formData = this.state.formData;
    const formErrors = {
      codingKnowledge: helper.requiredString(formData.codingKnowledge),
      educationalLevel: helper.requiredString(formData.educationalLevel),
      englishProficiency: helper.requiredString(formData.englishProficiency)
    };
    if (helper.isValidForm(formErrors)) {
      this.props.handleFormSubmit(formData);
    } else {
      this.setState({ formErrors });
    }
  };

  _onPrevious = event => {
    event.preventDefault();
    this.props.handleFormSubmit(this.state.formData, true);
  };

  render() {
    const { formData, formErrors } = this.state;
    return (
      <form onSubmit={this._onNext} id='education'>
        <div className='text-center mt-3'>
          <h3 className='font-weight-bold'>Educational Background</h3>
          <p className='text-muted'>Enrollment Application: Step 4 of 6</p>
        </div>
        <div className='form-group'>
          <label htmlFor='codingKnowledge' className={helper.addLabelErrorClass(formErrors, 'codingKnowledge')}>
            Coding Knowledge*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'codingKnowledge')}`}
            id='codingKnowledge'
            onChange={this.handleUserInput}
            defaultValue={formData.codingKnowledge}>
            <option value=''>-- Select --</option>
            <option value='BEGINNER'>Beginner</option>
            <option value='INTERMEDIATE'>Intermediate</option>
            <option value='PROFESSIONAL'>Professional</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='educationalLevel' className={helper.addLabelErrorClass(formErrors, 'educationalLevel')}>
            Educational Level*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'educationalLevel')}`}
            id='educationalLevel'
            onChange={this.handleUserInput}
            defaultValue={formData.educationalLevel}>
            <option value=''>-- Select --</option>
            <option value='HIGHSCHOOL'>High School</option>
            <option value='ASSOCIATE'>Associate</option>
            <option value='UNDERGRADUATE'>Undergraduate</option>
            <option value='MASTERS'>Masters</option>
            <option value='PHD'>PhD</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='major' className={helper.addLabelErrorClass(formErrors, 'major')}>
            Major
          </label>
          <input
            type='text'
            className={`form-control ${helper.addInputErrorClass(formErrors, 'major')}`}
            id='major'
            placeholder='Major'
            onChange={this.handleUserInput}
            defaultValue={formData.major}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='englishProficiency' className={helper.addLabelErrorClass(formErrors, 'englishProficiency')}>
            English Proficiency*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'englishProficiency')}`}
            id='englishProficiency'
            onChange={this.handleUserInput}
            defaultValue={formData.englishProficiency}>
            <option value=''>-- Select --</option>
            <option value='NATIVE'>Native</option>
            <option value='PROFICIENT'>Proficient</option>
            <option value='INTERMEDIATE'>Intermediate</option>
            <option value='ELEMENTARY'>Elementary</option>
            <option value='BEGINNER'>Beginner</option>
          </select>
        </div>
        <div className='mt-3'>
          <button type='button' onClick={this._onPrevious} className='btn btn-bg-black'>
            Previous
          </button>
          <button type='submit' className='btn btn-custom float-right'>
            Next
          </button>
        </div>
      </form>
    );
  }
}

Education.propTypes = {
  handleFormSubmit: PropTypes.func,
  educationInfo: PropTypes.object
};

export default Education;
