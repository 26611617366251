import React from 'react';

import { GOOGLE_API_KEY } from '../keys';
import LocationMap from './LocationMap';

class Contact extends React.Component {
  constructor() {
    super();
    this.state = { formData: {}, formErrors: {} };
  }

  render() {
    return (
      <section className='section bg-gray' id='contact'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2'>
              <h1 className='section-title text-center'>Contact Us</h1>
              <div className='section-title-border margin-t-20' />
              <p className='section-subtitle text-muted text-center font-secondary padding-t-30'>
                Contact us for course details, course schedule, tuition structure, exclusive student offers, group
                offers and many more.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='mt-4 pt-4'>
                <p className='mt-4'>
                  <span className='h5'>Office Address:</span>
                  <br />{' '}
                  <span className='text-muted d-block mt-2'>
                    1201 S. Sherman St. Suite 200
                    <br /> Richardson, TX 75081
                  </span>
                </p>
                <p className='mt-4'>
                  <span className='h5'>Working Hours:</span>
                  <br />
                  <span className='text-muted d-block mt-2'>6:00 PM To 9:00 PM (Tuesday, Thursday)</span>
                  <span className='text-muted d-block mt-2'>9:30 AM To 5:00 PM (Saturday, Sunday)</span>
                </p>
              </div>
            </div>
            <div className='col-lg-8 mt-4 pt-4'>
              <LocationMap
                isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=weekly`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `300px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Contact;
