import React from 'react';

//import { Link } from 'react-router-dom';

class Features extends React.Component {
  render() {
    return (
      <section className='section bg-white' id='features'>
        <div className='container'>
          <div className='row vertical-content'>
            <div className='col-lg-5'>
              <div className='features-box'>
                <h3>We are creative, innovative & solution centered</h3>
                <p className='text-muted web-desc'>
                  CodingISD trainers are passionate about teaching our students and are at the forefront of their
                  scholarly or creative work. We focus on discovery learning, rather than input teaching. We draw the
                  learning out, rather than pumping it in.
                </p>
                <ul className='text-muted list-unstyled margin-t-30 features-item-list'>
                  <li className=''>Live project based training</li>
                  <li className=''>High skilled experienced instructors</li>
                  <li className=''>Comprehensive course materials</li>
                  <li className=''>Job placement support</li>
                </ul>
                {/* <Link to='' className='btn btn-custom margin-t-30 waves-effect waves-light'>
                  Learn More <i className='mdi mdi-arrow-right' />
                </Link> */}
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='features-img features-right text-right'>
                <img src='images/online-world.svg' alt='macbook' className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Features;
