import PropTypes from "prop-types";
import React from "react";

import * as helper from "../../helper";

class Personal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: this.props.personalInfo, formErrors: {} };
  }

  handleUserInput = (event) => {
    const updatedFormData = { ...this.state.formData };
    updatedFormData[event.target.id] = event.target.value;
    this.setState({ formData: updatedFormData });
  };

  _onNext = (event) => {
    event.preventDefault();
    const formData = this.state.formData;
    const formErrors = {
      firstName: helper.requiredString(formData.firstName),
      lastName: helper.requiredString(formData.lastName),
      gender: helper.requiredString(formData.gender),
      email: helper.requiredEmail(formData.email),
      phone: helper.requiredPhone(formData.phone),
      dob: helper.requiredDate(formData.dob),
    };
    console.log(formErrors, formData.dob);
    if (helper.isValidForm(formErrors)) {
      this.props.handleFormSubmit(formData);
    } else {
      this.setState({ formErrors });
    }
  };

  _onPrevious = (event) => {
    event.preventDefault();
    this.props.handleFormSubmit(this.state.formData, true);
  };

  render() {
    const { formData, formErrors } = this.state;
    return (
      <form onSubmit={this._onNext} id="personal">
        <div className="text-center mt-3">
          <h3 className="font-weight-bold">Personal Information</h3>
          <p className="text-muted">Enrollment Application: Step 2 of 6</p>
        </div>
        <div className="form-group">
          <label
            htmlFor="firstName"
            className={helper.addLabelErrorClass(formErrors, "firstName")}
          >
            First Name*
          </label>
          <input
            type="text"
            className={`form-control ${helper.addInputErrorClass(
              formErrors,
              "firstName"
            )}`}
            id="firstName"
            placeholder="First Name"
            onChange={this.handleUserInput}
            defaultValue={formData.firstName}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="lastName"
            className={helper.addLabelErrorClass(formErrors, "lastName")}
          >
            Last Name*
          </label>
          <input
            type="text"
            className={`form-control ${helper.addInputErrorClass(
              formErrors,
              "lastName"
            )}`}
            id="lastName"
            placeholder="Last Name"
            onChange={this.handleUserInput}
            defaultValue={formData.lastName}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="gender"
            className={helper.addLabelErrorClass(formErrors, "gender")}
          >
            Gender*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(
              formErrors,
              "gender"
            )}`}
            id="gender"
            onChange={this.handleUserInput}
            defaultValue={formData.gender}
          >
            <option value="">-- Select --</option>
            <option value="MALE">Male</option>
            <option value="FEMALE">Female</option>
          </select>
        </div>
        <div className="form-group">
          <label
            htmlFor="email"
            className={helper.addLabelErrorClass(formErrors, "email")}
          >
            Email*
          </label>
          <input
            type="email"
            className={`form-control ${helper.addInputErrorClass(
              formErrors,
              "email"
            )}`}
            id="email"
            placeholder="Enter Email"
            onChange={this.handleUserInput}
            defaultValue={formData.email}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="phone"
            className={helper.addLabelErrorClass(formErrors, "phone")}
          >
            Phone*
          </label>
          <input
            type="tel"
            className={`form-control ${helper.addInputErrorClass(
              formErrors,
              "phone"
            )}`}
            id="phone"
            maxLength="10"
            minLength="10"
            placeholder="0000000000"
            onChange={this.handleUserInput}
            defaultValue={formData.phone}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="dob"
            className={helper.addLabelErrorClass(formErrors, "dob")}
          >
            Date of Birth*
          </label>
          <input
            type="date"
            className={`form-control ${helper.addInputErrorClass(
              formErrors,
              "dob"
            )}`}
            id="dob"
            placeholder="12/31/1990"
            min="1950-01-01"
            max="2005-12-31"
            onChange={this.handleUserInput}
            defaultValue={formData.dob}
          />
        </div>
        <div className="mt-3">
          <button
            type="button"
            onClick={this._onPrevious}
            className="btn btn-bg-black"
          >
            Previous
          </button>
          <button type="submit" className="btn btn-custom float-right">
            Next
          </button>
        </div>
      </form>
    );
  }
}

Personal.propTypes = {
  handleFormSubmit: PropTypes.func,
  personalInfo: PropTypes.object,
};

export default Personal;
