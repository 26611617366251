import PropTypes from 'prop-types';
import React from 'react';

import * as helper from '../../helper';

class AdditionalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: this.props.additionalInfo, formErrors: {} };
  }

  handleUserInput = event => {
    const updatedFormData = { ...this.state.formData };
    updatedFormData[event.target.id] = event.target.value;
    this.setState({ formData: updatedFormData });
  };

  _onNext = event => {
    event.preventDefault();
    const formData = this.state.formData;
    const formErrors = {
      eligibleToWork: helper.requiredString(formData.eligibleToWork),
      howDidYouHearAboutUs: helper.requiredString(formData.howDidYouHearAboutUs),
      aboutMyself: helper.requiredString(formData.aboutMyself),
      reasonForChoosing: helper.requiredString(formData.reasonForChoosing)
    };
    if (helper.isValidForm(formErrors)) {
      this.props.handleFormSubmit(formData);
    } else {
      this.setState({ formErrors });
    }
  };

  _onPrevious = event => {
    event.preventDefault();
    this.props.handleFormSubmit(this.state.formData, true);
  };

  render() {
    const { formData, formErrors } = this.state;
    return (
      <form onSubmit={this._onNext} id='education'>
        <div className='text-center mt-3'>
          <h3 className='font-weight-bold'>Additional Information</h3>
          <p className='text-muted'>Enrollment Application: Step 6 of 6</p>
        </div>
        <div className='form-group'>
          <label htmlFor='eligibleToWork' className={helper.addLabelErrorClass(formErrors, 'eligibleToWork')}>
            Eligible to Work in the US*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'eligibleToWork')}`}
            id='eligibleToWork'
            onChange={this.handleUserInput}
            defaultValue={formData.eligibleToWork}>
            <option value=''>-- Select --</option>
            <option value='YES'>Yes</option>
            <option value='NO'>No</option>
            <option value='GETTING SOON'>Getting Soon</option>
          </select>
        </div>
        <div className='form-group'>
          <label
            htmlFor='howDidYouHearAboutUs'
            className={helper.addLabelErrorClass(formErrors, 'howDidYouHearAboutUs')}>
            How Did You Hear About Us*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'howDidYouHearAboutUs')}`}
            id='howDidYouHearAboutUs'
            onChange={this.handleUserInput}
            defaultValue={formData.howDidYouHearAboutUs}>
            <option value=''>-- Select --</option>
            <option value='WEBSITE'>CodingISD Website</option>
            <option value='REFERENCED'>Referenced by Someone</option>
            <option value='OTHER'>Other</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='aboutMyself' className={helper.addLabelErrorClass(formErrors, 'aboutMyself')}>
            Tell Us About Yourself*
          </label>
          <textarea
            className={`form-control ${helper.addInputErrorClass(formErrors, 'aboutMyself')}`}
            id='aboutMyself'
            placeholder='Tell Us About Yourself'
            onChange={this.handleUserInput}
            defaultValue={formData.aboutMyself}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='reasonForChoosing' className={helper.addLabelErrorClass(formErrors, 'reasonForChoosing')}>
            Reason for Choosing CodingISD*
          </label>
          <textarea
            className={`form-control ${helper.addInputErrorClass(formErrors, 'reasonForChoosing')}`}
            id='reasonForChoosing'
            placeholder='Reason for Choosing CodingISD'
            onChange={this.handleUserInput}
            defaultValue={formData.reasonForChoosing}
          />
        </div>
        <div className='mt-3'>
          <button type='button' onClick={this._onPrevious} className='btn btn-bg-black'>
            Previous
          </button>
          <button type='submit' className='btn btn-custom float-right'>
            Submit
          </button>
        </div>
      </form>
    );
  }
}

AdditionalInfo.propTypes = {
  handleFormSubmit: PropTypes.func,
  additionalInfo: PropTypes.object
};

export default AdditionalInfo;
