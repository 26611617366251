import PropTypes from 'prop-types';
import React from 'react';

import * as helper from '../../helper';

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: this.props.addressInfo, formErrors: {} };
  }

  handleUserInput = event => {
    const updatedFormData = { ...this.state.formData };
    updatedFormData[event.target.id] = event.target.value;
    this.setState({ formData: updatedFormData });
  };

  _onNext = event => {
    event.preventDefault();
    const formData = this.state.formData;
    const formErrors = {
      street1: helper.requiredString(formData.street1),
      city: helper.requiredString(formData.city),
      state: helper.requiredString(formData.state),
      zipcode: helper.requiredZipcode(formData.zipcode)
    };
    if (helper.isValidForm(formErrors)) {
      this.props.handleFormSubmit(formData);
    } else {
      this.setState({ formErrors });
    }
  };

  _onPrevious = event => {
    event.preventDefault();
    this.props.handleFormSubmit(this.state.formData, true);
  };

  render() {
    const { formData, formErrors } = this.state;
    return (
      <form onSubmit={this._onNext} id='address'>
        <div className='text-center mt-3'>
          <h3 className='font-weight-bold'>Address Information</h3>
          <p className='text-muted'>Enrollment Application: Step 3 of 6</p>
        </div>
        <div className='form-group'>
          <label htmlFor='street1' className={helper.addLabelErrorClass(formErrors, 'street1')}>
            Street Address*
          </label>
          <input
            type='text'
            className={`form-control ${helper.addInputErrorClass(formErrors, 'street1')}`}
            id='street1'
            placeholder='Street Address'
            onChange={this.handleUserInput}
            defaultValue={formData.street1}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='street2'>Street Address 2</label>
          <input
            type='text'
            className='form-control'
            id='street2'
            placeholder='Apartment, suite, unit, building, floor, etc.'
            onChange={this.handleUserInput}
            defaultValue={formData.street2}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='city' className={helper.addLabelErrorClass(formErrors, 'city')}>
            City*
          </label>
          <input
            type='text'
            className={`form-control ${helper.addInputErrorClass(formErrors, 'city')}`}
            id='city'
            placeholder='City'
            onChange={this.handleUserInput}
            defaultValue={formData.city}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='state' className={helper.addLabelErrorClass(formErrors, 'state')}>
            State*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'state')}`}
            id='state'
            onChange={this.handleUserInput}
            defaultValue={formData.state}>
            <option value=''>-- Select --</option>
            <option value='Alabama'>Alabama</option>
            <option value='Alaska'>Alaska</option>
            <option value='Arizona'>Arizona</option>
            <option value='Arkansas'>Arkansas</option>
            <option value='California'>California</option>
            <option value='Colorado'>Colorado</option>
            <option value='Connecticut'>Connecticut</option>
            <option value='Delaware'>Delaware</option>
            <option value='District of Columbia'>District of Columbia</option>
            <option value='Florida'>Florida</option>
            <option value='Georgia'>Georgia</option>
            <option value='Hawaii'>Hawaii</option>
            <option value='Idaho'>Idaho</option>
            <option value='Illinois'>Illinois</option>
            <option value='Indiana'>Indiana</option>
            <option value='Iowa'>Iowa</option>
            <option value='Kansas'>Kansas</option>
            <option value='Kentucky'>Kentucky</option>
            <option value='Louisiana'>Louisiana</option>
            <option value='Maine'>Maine</option>
            <option value='Maryland'>Maryland</option>
            <option value='Massachusetts'>Massachusetts</option>
            <option value='Michigan'>Michigan</option>
            <option value='Minnesota'>Minnesota</option>
            <option value='Mississippi'>Mississippi</option>
            <option value='Missouri'>Missouri</option>
            <option value='Montana'>Montana</option>
            <option value='Nebraska'>Nebraska</option>
            <option value='Nevada'>Nevada</option>
            <option value='New Hampshire'>New Hampshire</option>
            <option value='New Jersey'>New Jersey</option>
            <option value='New Mexico'>New Mexico</option>
            <option value='New York'>New York</option>
            <option value='North Carolina'>North Carolina</option>
            <option value='North Dakota'>North Dakota</option>
            <option value='Ohio'>Ohio</option>
            <option value='Oklahoma'>Oklahoma</option>
            <option value='Oregon'>Oregon</option>
            <option value='Pennsylvania'>Pennsylvania</option>
            <option value='Rhode Island'>Rhode Island</option>
            <option value='South Carolina'>South Carolina</option>
            <option value='South Dakota'>South Dakota</option>
            <option value='Tennessee'>Tennessee</option>
            <option value='Texas'>Texas</option>
            <option value='Utah'>Utah</option>
            <option value='Vermont'>Vermont</option>
            <option value='Virginia'>Virginia</option>
            <option value='Washington'>Washington</option>
            <option value='West Virginia'>West Virginia</option>
            <option value='Wisconsin'>Wisconsin</option>
            <option value='Wyoming'>Wyoming</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='zipcode' className={helper.addLabelErrorClass(formErrors, 'zipcode')}>
            ZIP Code*
          </label>
          <input
            type='text'
            className={`form-control ${helper.addInputErrorClass(formErrors, 'zipcode')}`}
            id='zipcode'
            maxLength='5'
            minLength='5'
            placeholder='5 digit ZIP code'
            onChange={this.handleUserInput}
            defaultValue={formData.zipcode}
          />
        </div>
        <div className='mt-3'>
          <button type='button' onClick={this._onPrevious} className='btn btn-bg-black'>
            Previous
          </button>
          <button type='submit' className='btn btn-custom float-right'>
            Next
          </button>
        </div>
      </form>
    );
  }
}

Address.propTypes = {
  handleFormSubmit: PropTypes.func,
  addressInfo: PropTypes.object
};

export default Address;
