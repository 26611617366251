import 'url-search-params-polyfill';

import React from 'react';

import { apigClient } from '../awsClient';
import AdditionalInfo from '../components/Application/AdditionalInfo';
import Address from '../components/Application/Address';
import Background from '../components/Application/Background';
import Batch from '../components/Application/Batch';
import Education from '../components/Application/Education';
import Personal from '../components/Application/Personal';
import Message from '../components/Message';
import Aux from '../hoc/Aux_';

class Application extends React.Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const campName = params.get('campName');
    const batchInfo = { campName };
    const personalInfo = {};
    const addressInfo = {};
    const educationInfo = {};
    const background = {};
    const additionalInfo = {};

    this.state = {
      batchInfo,
      personalInfo,
      addressInfo,
      educationInfo,
      background,
      additionalInfo,
      step: 1
    };
  }

  handleBatchFormSubmit = formData => {
    const { step } = this.state;
    this.setState({ batchInfo: formData, step: step + 1 });
  };

  handlePersonalFormSubmit = (formData, backward) => {
    const { step } = this.state;
    this.setState({ personalInfo: formData, step: backward ? step - 1 : step + 1 });
  };

  handleAddressFormSubmit = (formData, backward) => {
    const { step } = this.state;
    this.setState({ addressInfo: formData, step: backward ? step - 1 : step + 1 });
  };

  handleEducationFormSubmit = (formData, backward) => {
    const { step } = this.state;
    this.setState({ educationInfo: formData, step: backward ? step - 1 : step + 1 });
  };

  handleBackgroundFormSubmit = (formData, backward) => {
    const { step } = this.state;
    this.setState({ background: formData, step: backward ? step - 1 : step + 1 });
  };

  submitApplication = async (formData, backward) => {
    if (backward) {
      const { step } = this.state;
      this.setState({ additionalInfo: formData, step: step - 1 });
    } else {
      const { batchInfo, personalInfo, addressInfo, educationInfo, background } = this.state;
      const body = {
        batchInfo,
        personalInfo,
        addressInfo,
        educationInfo,
        background,
        additionalInfo: formData
      };
      const { status } = await apigClient.applicationsPost(null, body, {});
      let message;
      if (status === 200) {
        message = { type: 'success', text: 'We have received your enrollment application. We will contact you soon.' };
      } else {
        message = { type: 'error', text: 'We are not able to serve you at the moment. Please try again later.' };
      }

      this.setState({ message });
    }
  };

  render() {
    return (
      <Aux>
        <div className='account-home-btn d-sm-block'>
          <a href='/' className='text-white'>
            <i className='mdi mdi-home h1' />
          </a>
        </div>

        <section className='bg-account-pages height-100vh'>
          <div className='display-table'>
            <div className='display-table-cell'>
              <div className='container'>
                <div className='row justify-content-center'>
                  <div className='col-lg-6'>
                    <div className='card account-card'>
                      <div className='card-body'>
                        <div className='p-3'>
                          {this.state.message && (
                            <div>
                              <Message message={this.state.message} />
                              <div className='mt-3'>
                                <a href='/' className='btn btn-custom btn-block'>
                                  Return to Home
                                </a>
                              </div>
                            </div>
                          )}
                          {!this.state.message && this.state.step === 1 && (
                            <div>
                              <Batch handleFormSubmit={this.handleBatchFormSubmit} batchInfo={this.state.batchInfo} />
                            </div>
                          )}
                          {!this.state.message && this.state.step === 2 && (
                            <div>
                              <Personal
                                handleFormSubmit={this.handlePersonalFormSubmit}
                                personalInfo={this.state.personalInfo}
                              />
                            </div>
                          )}
                          {!this.state.message && this.state.step === 3 && (
                            <div>
                              <Address
                                handleFormSubmit={this.handleAddressFormSubmit}
                                addressInfo={this.state.addressInfo}
                              />
                            </div>
                          )}
                          {!this.state.message && this.state.step === 4 && (
                            <div>
                              <Education
                                handleFormSubmit={this.handleEducationFormSubmit}
                                educationInfo={this.state.educationInfo}
                              />
                            </div>
                          )}
                          {!this.state.message && this.state.step === 5 && (
                            <div>
                              <Background
                                handleFormSubmit={this.handleBackgroundFormSubmit}
                                background={this.state.background}
                              />
                            </div>
                          )}
                          {!this.state.message && this.state.step === 6 && (
                            <div>
                              <AdditionalInfo
                                handleFormSubmit={this.submitApplication}
                                additionalInfo={this.state.additionalInfo}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Aux>
    );
  }
}
export default Application;
