import React from "react";

class Testimony extends React.Component {
  render() {
    return (
      <section className="section" id="testi">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">What they've said</h1>
              <div className="section-title-border margin-t-20" />
              <p className="section-subtitle text-muted text-center font-secondary padding-t-30">
                Here we are presenting exclusive review and testimonials which
                are given by our students who got benefited from our training.
              </p>
            </div>
          </div>
          <div className="row margin-t-50">
            <div className="col-lg-4">
              <div className="testimonial-box hover-effect margin-t-30">
                <img
                  src="images/testimonials/user-11.png"
                  alt=""
                  className="img-fluid d-block img-thumbnail rounded-circle"
                />
                <div className="testimonial-decs">
                  <p className="text-muted text-center mb-0">
                    “Choose CodingISD was the best decision I made to start my
                    career in IT. I am proud to be part of CodingISD”{" "}
                  </p>
                </div>
                <h5 className="text-center text-uppercase padding-t-15">
                  Test Automation Architect -{" "}
                  <span className="text-muted text-capitalize">Former Student</span>
                </h5>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="testimonial-box hover-effect margin-t-30">
                <img
                  src="images/testimonials/user-21.png"
                  alt=""
                  className="img-fluid d-block img-thumbnail rounded-circle"
                />
                <div className="testimonial-decs">
                  <p className="text-muted text-center mb-0">
                    “Opportunities don’t happen, you create them and CodingISD
                    is the best place to create opportunities for you.”{" "}
                  </p>
                </div>
                <h5 className="text-center text-uppercase padding-t-15">
                  Test Automation Engineer -{" "}
                  <span className="text-muted text-capitalize">Former Student</span>
                </h5>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="testimonial-box hover-effect margin-t-30">
                <img
                  src="images/testimonials/user-31.png"
                  alt=""
                  className="img-fluid d-block img-thumbnail rounded-circle"
                />
                <div className="testimonial-decs">
                  <p className="text-muted text-center mb-0">
                    “If you need to change your life come to CodingISD. Not only
                    the training but people around you give positive energy.”{" "}
                  </p>
                </div>
                <h5 className="text-center text-uppercase padding-t-15">
                  Software Quality Assurance -{" "}
                  <span className="text-muted text-capitalize">Former Student</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Testimony;
