import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className='footer'>
        <div className='container'>
          {/*<div className='row'>
            <div className='col-lg-3 margin-t-20'>
              <h4>CodingISD</h4>
              <div className='text-muted margin-t-20'>
                <ul className='list-unstyled footer-list'>
                  <li>
                    <Link to=''>Home</Link>
                  </li>
                  <li>
                    <Link to=''>About us</Link>
                  </li>
                  <li>
                    <Link to=''>Careers</Link>
                  </li>
                  <li>
                    <Link to=''>Contact us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 margin-t-20'>
              <h4>Information</h4>
              <div className='text-muted margin-t-20'>
                <ul className='list-unstyled footer-list'>
                  <li>
                    <Link to=''>Terms & Condition</Link>
                  </li>
                  <li>
                    <Link to=''>Jobs</Link>
                  </li>
                  <li>
                    <Link to=''>Bookmarks</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 margin-t-20'>
              <h4>Support</h4>
              <div className='text-muted margin-t-20'>
                <ul className='list-unstyled footer-list'>
                  <li>
                    <Link to=''>FAQ</Link>
                  </li>
                  <li>
                    <Link to=''>Discussion</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 margin-t-20'>
              <h4>Subscribe</h4>
              <div className='text-muted margin-t-20'>
                <p />
              </div>
              <form className='form subscribe'>
                <input placeholder='Email' className='form-control' required />
                <Link to='' className='submit'>
                  <i className='pe-7s-paper-plane' />
                </Link>
              </form>
            </div>
    </div>*/}
          <div className='row'>
            <div className='col-lg-12'>
              <div className='float-right pull-none '>
                <p className='copy-rights text-muted'>{new Date().getFullYear()} © CodingISD</p>
              </div>
              <div className='clearfix' />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
