import PropTypes from 'prop-types';
import React from 'react';

import * as helper from '../../helper';

class Background extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: this.props.background, formErrors: {} };
  }

  handleUserInput = event => {
    const updatedFormData = { ...this.state.formData };
    updatedFormData[event.target.id] = event.target.value;
    this.setState({ formData: updatedFormData });
  };

  _onNext = event => {
    event.preventDefault();
    const formData = this.state.formData;
    const formErrors = {
      academicProblem: helper.requiredString(formData.academicProblem),
      criminalHistory: helper.requiredString(formData.criminalHistory),
      pendingCharges: helper.requiredString(formData.pendingCharges),
      misconduct: helper.requiredString(formData.misconduct)
    };
    if (helper.isValidForm(formErrors)) {
      this.props.handleFormSubmit(formData);
    } else {
      this.setState({ formErrors });
    }
  };

  _onPrevious = event => {
    event.preventDefault();
    this.props.handleFormSubmit(this.state.formData, true);
  };

  render() {
    const { formData, formErrors } = this.state;
    return (
      <form onSubmit={this._onNext} id='education'>
        <div className='text-center mt-3'>
          <h3 className='font-weight-bold'>Personal Background</h3>
          <p className='text-muted'>Enrollment Application: Step 5 of 6</p>
        </div>
        <div className='form-group'>
          <label htmlFor='academicProblem' className={helper.addLabelErrorClass(formErrors, 'academicProblem')}>
            Have you ever been dismissed, expelled, suspended, placed on academic probation, given an academic warning,
            or disciplined by any secondary school, college, university, graduate, or professional school for academic
            or any other reasons?*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'academicProblem')}`}
            id='academicProblem'
            onChange={this.handleUserInput}
            defaultValue={formData.academicProblem}>
            <option value=''>-- Select --</option>
            <option value='YES'>Yes</option>
            <option value='NO'>No</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='criminalHistory' className={helper.addLabelErrorClass(formErrors, 'criminalHistory')}>
            Have you ever been cited for, charged with, taken into custody for, arrested for, indicted, tried for,
            pleaded guilty to, or convicted of, the violation of any law or ordinance?*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'criminalHistory')}`}
            id='criminalHistory'
            onChange={this.handleUserInput}
            defaultValue={formData.criminalHistory}>
            <option value=''>-- Select --</option>
            <option value='YES'>Yes</option>
            <option value='NO'>No</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='pendingCharges' className={helper.addLabelErrorClass(formErrors, 'pendingCharges')}>
            Are there any criminal or disciplinary charges pending or expected to be brought against you?*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'pendingCharges')}`}
            id='pendingCharges'
            onChange={this.handleUserInput}
            defaultValue={formData.pendingCharges}>
            <option value=''>-- Select --</option>
            <option value='YES'>Yes</option>
            <option value='NO'>No</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='misconduct' className={helper.addLabelErrorClass(formErrors, 'misconduct')}>
            Have you ever been prohibited from practicing in any professional capacity due to or as a result of alleged
            misconduct on your part?*
          </label>
          <select
            className={`form-control ${helper.addInputErrorClass(formErrors, 'misconduct')}`}
            id='misconduct'
            onChange={this.handleUserInput}
            defaultValue={formData.misconduct}>
            <option value=''>-- Select --</option>
            <option value='YES'>Yes</option>
            <option value='NO'>No</option>
          </select>
        </div>
        <div className='mt-3'>
          <button type='button' onClick={this._onPrevious} className='btn btn-bg-black'>
            Previous
          </button>
          <button type='submit' className='btn btn-custom float-right'>
            Next
          </button>
        </div>
      </form>
    );
  }
}

Background.propTypes = {
  handleFormSubmit: PropTypes.func,
  background: PropTypes.object
};

export default Background;
