import { Link } from 'react-router-dom';
import React from 'react';

class BootCamps extends React.Component {
  render() {
    return (
      <section className='section pt-5 bg-gray' id='bootCamps'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2'>
              <h1 className='section-title text-center'>Our Boot Camps</h1>
              <div className='section-title-border margin-t-20' />
            </div>
          </div>
          <div className='row margin-t-50'>
          <div className='col-lg-4' id='DS'>
              <div className='text-center pricing-box bg-white hover-effect price-active'>
                <h4 className='text-uppercase'>Data Science</h4>
                <h1>4 Month</h1>
                <h6 className='text-capitalize text-muted'>No background required</h6>
                <div className='pricing-border' />
                <div className='plan-features margin-t-30'>
                  <p>
                    Big Data Overview<b className='text-custom'> </b>
                  </p>
                  <p>
                    AI Development Basics<b className='text-custom'> </b>
                  </p>
                  <p>
                    Core Python<b className='text-custom'> </b>
                  </p>
                  <p>
                    Popular Python Libraries<b className='text-custom'> </b>
                  </p>                  
                  <p>
                    Machine Learning Models<b className='text-custom'> </b>
                  </p>
                  <p>
                    SQL<b className='text-custom'> </b>
                  </p>
                  <p>
                    Data Visualization<b className='text-custom'> </b>
                  </p>
                  <p>
                    Tableau<b className='text-custom'> </b>
                  </p>
                  <p>
                    <b className='text-custom'> </b> Power BI
                  </p>
                  <p>
                    <b className='text-custom'> </b> And More...
                  </p>
                </div>
                <Link to='application?campName=DS' className='btn btn-custom waves-effect waves-light margin-t-30'>
                  Enroll Now
                </Link>
              </div>
            </div>            
            <div className='col-lg-4' id='SDET'>
              <div className='text-center pricing-box bg-white hover-effect'>
                <h4 className='text-uppercase'>SDET</h4>
                <h1>8 Month</h1>
                <h6 className='text-capitalize text-muted'>No background required</h6>
                <div className='pricing-border' />
                <div className='plan-features margin-t-30'>
                  <p>
                    <b className='text-custom'> </b> Computer Basics
                  </p>
<p>
                    <b className='text-custom'> </b> Operating System
                  </p>
                  <p>
                    <b className='text-custom'> </b> Software Development Life Cycle
                  </p>
                  <p>
                    <b className='text-custom'> </b> Software Testing Life Cycle
                  </p>
                  <p>
                    Core Java, JUnit <b className='text-custom'> </b>
                  </p>
                  <p>
                    CSS, HTML, JavaScript <b className='text-custom'> </b>
                  </p>
                  <p>
                    <b className='text-custom'> </b> Database and SQL
                  </p>
                  <p>
                    Selenium WebDriver <b className='text-custom'> </b>
                  </p>
                  <p>
                    TestNG, BDD Cucumber <b className='text-custom'> </b>
                  </p>
                  <p>
                    <b className='text-custom'> </b> And More...
                  </p>
                </div>
                <Link to='application?campName=SDET' className='btn btn-custom waves-effect waves-light margin-t-30'>
                  Enroll Now
                </Link>
              </div>
            </div>
            <div className='col-lg-4' id='WEB'>
              <div className='text-center pricing-box bg-white hover-effect'>
                <h4 className='text-uppercase'>Web Development</h4>
                <h1>12 Month</h1>
                <h6 className='text-capitalize text-muted'>No background required</h6>
                <div className='pricing-border' />
                <div className='plan-features margin-t-30'>
                  <p>
                    Web Development Basics <b className='text-custom'> </b>
                  </p>
                  <p>
                    HTML <b className='text-custom'> </b>
                  </p>
                  <p>
                    CSS <b className='text-custom'> </b>
                  </p>
                  <p>
                    Core JavaScript<b className='text-custom'> </b>
                  </p>
                  <p>
                    Advanced JavaScript<b className='text-custom'> </b>
                  </p>
                  <p>
                    React/Angular<b className='text-custom'> </b>
                  </p>
                  <p>
                    <b className='text-custom'> </b> Node JS
                  </p>
                  <p>
                    <b className='text-custom'> </b> Express
                  </p>
<p>
                    <b className='text-custom'> </b> Git & GitHub
                  </p>
                  <p>
                    <b className='text-custom'> </b> And More...
                  </p>
                </div>
                <Link to='application?campName=WEB' className='btn btn-custom waves-effect waves-light margin-t-30'>
                  Enroll Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default BootCamps;
